<template>
    <modal ref="modalCambiarClasificacion" titulo="Actualizar clasificación" no-aceptar adicional="Actualizar" @adicional="updateClasificacion">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center">
            <div class="col text-center">
                <p class="text-general">
                    ¿Está seguro de realizar esta acción?
                </p>
            </div>
        </div>
    </modal>
</template>
<script>
import Service from '~/services/configurar/admin/descuentosLista';

export default {
    
    data(){
        return {
            cargando: false,
            tipo: null,
            idCedis: null
        }
    },
    methods: {
        toggle(tipo, idCedis){
            this.tipo = tipo;
            this.idCedis = idCedis;
            this.$refs.modalCambiarClasificacion.toggle();
        },

        async updateClasificacion(){
            if(this.tipo === 'tendero'){
                this.updateTendero();
            }
            if(this.tipo === 'cliente'){
                this.updateClientes();
            }
        },

        async updateTendero(){
            try {
                this.cargando = true;

                const payload = {
                    idCedis: this.idCedis
                };
                const {data} = await Service.updateClasificacionTendero(payload);
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$refs.modalCambiarClasificacion.toggle();
                }
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargando = false;
            }
        },

        async updateClientes(){
            try {
                this.cargando = true;

                const payload = {
                    idCedis: this.idCedis
                };
                const {data} = await Service.updateClasificacionClientes(payload);
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$refs.modalCambiarClasificacion.toggle();
                }
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargando = false;
            }
        }
    }
}
</script>
